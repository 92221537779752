<template>
    <v-container class="amp-module-page">
        <a-form ref="form" :auto-submit="!isModuleBusy" @auto-submit="autoSave">
            <v-row v-if="generating">
                <v-col cols="12" md="8">
                    <a-alert type="info">
                        <p class="ma-0">
                            {{ generatingMessage }}
                        </p>
                    </a-alert>
                </v-col>
            </v-row>

            <v-row v-if="canChangePodcastStyle">
                <v-col cols="12" md="8">
                    <v-card>
                        <v-card-text>
                            <a-select-input
                                v-model="podcast.style_type"
                                hide-details
                                :items="podcastStyleItems"
                                label="Podcast Style"
                                :loading="loading"
                                :disabled="loading"
                            />
                        </v-card-text>
                        <v-card-text v-if="isInterviewStylePodcast">
                            <v-row dense>
                                <v-col class="flex-grow-1">
                                    <a-select-input
                                        v-model="podcast.audio_clip_url"
                                        hide-details
                                        :items="audioClips"
                                        label="Intro/Outro Audio"
                                        :rules="audioClipRules"
                                        item-text="title"
                                        item-value="url"
                                        :loading="loading"
                                        :disabled="loading"
                                    />
                                </v-col>
                                <v-col class="pl-4 pt-3 flex-grow-0">
                                    <audio-player-chip
                                        :src="activeAudioClipSrc"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12" md="4">
                    <a-alert
                        v-if="!canSelectInterviewStyleOption"
                        type="warning"
                        class="mt-4"
                    >
                        Blog post content and at least one supporting url from
                        article module is required to crate interview style
                        podcast.
                    </a-alert>
                </v-col>
            </v-row>

            <!-- headline -->
            <amp-row-first>
                <template #input>
                    <a-alert
                        v-for="(issue, i) in publishIssues"
                        :key="i"
                        :message="issue.message"
                    />
                    <a-text-input
                        ref="headline"
                        v-model="podcast.headline"
                        :loading="loading"
                        :disabled="loading"
                        label="Headline"
                        rules="required|max:80"
                        rows="1"
                        auto-grow
                        textarea
                        observed
                    />
                </template>
                <template #validation>
                    <amp-validation
                        :input="$refs.headline"
                        :loading="isLoading"
                    />
                </template>
            </amp-row-first>

            <!-- summary -->
            <amp-row>
                <template #input>
                    <a-content-editor
                        ref="summary"
                        v-model="podcast.summary"
                        :loading="loading"
                        :disabled="loading"
                        label="Summary"
                        rules="required|words:0,45"
                        :counter-value="getWordsCounter(45)"
                        :no-first-person="false"
                        :debounce="500"
                        observed
                        one-line
                    />
                </template>
                <template #validation>
                    <amp-validation :input="$refs.summary" :loading="isLoading">
                        <a-alert
                            :type="podcast.summary ? 'success' : 'info'"
                            :message="
                                actualWordsCount(
                                    podcast.summary,
                                    0,
                                    45,
                                    'Summary'
                                )
                            "
                        />
                        <a-alert
                            v-if="containsLinks"
                            :type="
                                summaryText === ''
                                    ? 'info'
                                    : allLinksReachable
                                      ? 'success'
                                      : 'warning'
                            "
                        >
                            <span v-if="allLinksReachable">
                                All links have been validated and are
                                accessible.
                            </span>

                            <span v-else>
                                One or more of the links provided could not be
                                reached. Please ensure that all links provided
                                are accessible.
                            </span>
                        </a-alert>
                    </amp-validation>
                </template>
            </amp-row>

            <template v-if="isStandardPodcast">
                <!-- voice -->
                <amp-row>
                    <template #input>
                        <v-row class="ma-0">
                            <v-col class="pa-0 flex-grow-1">
                                <a-select-input
                                    v-model="videoVoiceId"
                                    :loading="loading"
                                    :disabled="loading"
                                    label="Voice"
                                    :items="video_voices"
                                    item-text="voice_name"
                                    item-value="id"
                                />
                            </v-col>
                            <v-col class="pa-0 pl-4 pt-2 flex-grow-0">
                                <audio-player-chip :src="activeVoiceSrc" />
                            </v-col>
                        </v-row>
                    </template>

                    <template #validation>
                        <a-alert type="info">
                            Copy below will be podcasted with selected Voice,
                            use play to try different voice variations.
                        </a-alert>
                    </template>
                </amp-row>

                <!-- content -->
                <amp-row>
                    <template #input>
                        <a-text-input
                            ref="content"
                            v-model="content"
                            :loading="isLoading"
                            label="Content"
                            rules="required|words:300,1000"
                            :counter-value="getWordsCounter(1000)"
                            auto-grow
                            textarea
                            observed
                        />
                    </template>
                    <template #validation>
                        <amp-validation
                            :input="$refs.content"
                            :loading="isLoading"
                        >
                            <a-alert
                                :type="content ? 'success' : 'info'"
                                :message="
                                    actualWordsCount(
                                        content,
                                        300,
                                        1000,
                                        'Content'
                                    )
                                "
                            />
                        </amp-validation>
                    </template>
                </amp-row>
            </template>

            <!-- media -->
            <amp-row>
                <template #input>
                    <media-preview-list
                        :media-resources="podcast.media_resources"
                        :user-id="podcast.user_id"
                        :allow-add="!hasMedia"
                        :allow-delete="false"
                        :options="mediaOptions"
                        class="pt-6 pb-4 px-1"
                        module-title="Podcast"
                        :loading="loading"
                        :disabled="loading"
                        @mediaFiles="onMediaFilesUpdate"
                    />

                    <validation-provider
                        ref="media_resources"
                        name="media_resources"
                    >
                        <input
                            v-model="podcast.media_resources"
                            type="hidden"
                        />
                    </validation-provider>
                </template>
            </amp-row>

            <!-- Topics -->
            <template v-if="canSeeTopics">
                <amp-row>
                    <template #input>
                        <div class="font-weight-bold">Topics</div>

                        <a-text-input
                            v-model="podcast.topic_1"
                            label="Topic 1"
                            rules="required"
                            :loading="loading"
                            :disabled="loading"
                        />

                        <a-text-input
                            v-model="podcast.topic_2"
                            label="Topic 2"
                            rules="required"
                            :loading="loading"
                            :disabled="loading"
                        />
                    </template>

                    <template #validation>
                        <a-alert v-if="!hasAllTopics" type="error" class="mt-6">
                            Both topics are required
                        </a-alert>
                    </template>
                </amp-row>

                <amp-row>
                    <template #input>
                        <div class="font-weight-bold">About the company</div>

                        <a-text-input
                            v-model="aboutCompany"
                            label="About"
                            :rules="{
                                required: true,
                                max: 2048
                            }"
                            placeholder="Start with, The company..."
                            :loading="loading"
                            :disabled="loading"
                        />
                    </template>

                    <template #validation>
                        <a-alert v-if="aboutCompany === ''" type="error">
                            Company detail is required
                        </a-alert>
                    </template>
                </amp-row>

                <amp-row>
                    <template #input>
                        <div class="d-flex justify-space-between">
                            <v-btn
                                v-if="hasTranscript"
                                @click="displayTranscript"
                            >
                                Show Transcript
                            </v-btn>

                            <v-spacer />

                            <v-btn
                                v-if="!generating"
                                color="primary"
                                :disabled="!canGenerateTranscript"
                                @click.stop="openGenerateTranscriptModal"
                            >
                                {{ hasTranscript ? 'Regenerate' : 'Generate' }}
                                Transcript
                            </v-btn>
                        </div>
                    </template>
                </amp-row>
            </template>

            <!-- Transcript -->
            <amp-row v-if="canSeeTranscript">
                <template #input>
                    <div class="font-weight-bold">Transcript</div>

                    <div class="transcript-captions-wrapper">
                        <a-text-input
                            v-for="(caption, i) in podcast.podcast_captions"
                            :key="i"
                            v-model="caption.content"
                            rules="required"
                            auto-grow
                            textarea
                            rows="1"
                            :class="{
                                host: hostVoiceIds.includes(
                                    caption.video_voice_id
                                )
                            }"
                            hide-details
                            :loading="loading"
                            :disabled="loading"
                        />
                    </div>

                    <v-btn @click="displayTopics">Show Topics</v-btn>
                </template>

                <template #validation>
                    <a-alert
                        :type="hasCompleteTranscript ? 'success' : 'error'"
                    >
                        Transcript must not include empty dialogs
                    </a-alert>
                </template>
            </amp-row>

            <amp-row-last />

            <generate-transcript-modal
                :generating="generating"
                @confirm="generateTranscript"
            />

            <generate-standard-podcast-modal
                :generating="generating"
                @cancel="onCancelStandardPodcastGeneration"
                @confirm="generateStandardPodcast"
            />
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { Route, NavigationGuardNext } from 'vue-router';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

import { Endpoint, WordsCounter, ValidateLinksReachability } from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { AudioPlayerChip } from '@/components/AudioPlayerChip';
import { MediaPreviewList } from '@/components/Media';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AContentEditor } from '@/components/AForm/Inputs/AContentEditor';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';

import {
    areMediaResourcesEqual,
    scrollTop,
    stripHtml,
    wordCount
} from '@/utils/helpers';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';

import type { AmpModules } from '@/types/Announcement';
import type { FormMediaResource } from '@/types/Media';
import {
    PodcastStatus,
    PodcastStyleType,
    PodcastAudioClip,
    Podcast as TPodcast
} from '@/types/Podcast';
import type { VideoVoice } from '@/types/Video';
import type { ModuleLink } from '@/types/ModuleLink';
import type { MediaResource } from '@/types/MediaResource';

import GenerateTranscriptModal from './GenerateTranscriptModal.vue';
import GenerateStandardPodcastModal from './GenerateStandardPodcastModal.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        AForm,
        AAlert,
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpValidation,
        ATextInput,
        AContentEditor,
        ASelectInput,
        AudioPlayerChip,
        MediaPreviewList,
        GenerateTranscriptModal,
        GenerateStandardPodcastModal
    },
    computed: {
        ...mapGetters('user', ['isEditor'])
    }
})
export default class Podcast extends mixins(
    Endpoint,
    WordsCounter,
    ValidateLinksReachability
) {
    isEditor!: boolean;

    $refs!: {
        form: InstanceType<typeof AForm>;
        headline: InstanceType<typeof ATextInput>;
        summary: InstanceType<typeof ATextInput>;
        content: InstanceType<typeof ATextInput>;
        media_resources: InstanceType<typeof ValidationProvider>;
    };

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @InjectReactive({
        from: 'isModuleBusy',
        default() {
            return false;
        }
    })
    isModuleBusy!: boolean;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    podcast: Partial<TPodcast> = {
        media_resources: [],
        podcast_captions: [
            {
                podcast_id: 0,
                video_voice_id: 75,
                content: ''
            }
        ]
    };

    @Watch('podcast.media_resources', { deep: true })
    onMediaResourcesChange(a: MediaResource[], b: MediaResource[]) {
        if (this.isReadyForChanges && !areMediaResourcesEqual(a, b)) {
            this.$refs.media_resources.setFlags({
                dirty: true,
                changed: true,
                touched: true
            });
        }
    }

    video_voices: VideoVoice[] = [];

    endpoint = '/podcasts/edit';

    isSaving = false;
    isReadyForChanges = false;
    generatePreview = false;

    @Watch('podcast.summary')
    onSummaryTextChange(value: string) {
        this.validateLinksReachability(value);
    }

    link: ModuleLink[] = [
        {
            type: 'primary',
            label: 'Review',
            to: this.reviewLink
        }
    ];

    showTopics = false;
    showTranscript = false;

    @Watch('showTranscript')
    onShowTranscriptChange(show: boolean) {
        if (show) {
            this.showTopics = false;
        }
    }

    generating = false;
    generatingMessage = '';

    autoCheckStatusInterval: ReturnType<typeof setInterval> | null = null;

    @Watch('podcast.style_type')
    onPodcastStyleChange(
        style: PodcastStyleType,
        prevStyle: PodcastStyleType | undefined
    ) {
        if (prevStyle !== undefined) {
            if (style === PodcastStyleType.INTERVIEW) {
                if (this.hasTranscript) {
                    this.showTranscript = true;
                } else {
                    this.showTopics = true;
                }
            }

            if (style === PodcastStyleType.STANDARD && this.hasAllTopics) {
                this.openGenerateStandardPodcastModal();
            }
        }
    }
    audioClips: PodcastAudioClip[] = [];
    aboutCompany = '';
    hostVoiceIds: Array<number> = [];

    get moduleId() {
        return this.modules?.podcast_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get summaryText() {
        return stripHtml(this.podcast.summary || '');
    }

    get activeVoiceSrc() {
        const active = this.video_voices.find(
            voice => voice.id === this.videoVoiceId
        );
        if (active) {
            return active.route;
        }

        return '';
    }

    get hasMedia() {
        return this.podcast.media_resources?.length;
    }

    get publishIssues() {
        return this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-publish-podcast`
        );
    }

    get mediaOptions() {
        return {
            announcement_id: this.announcementId
        };
    }

    get reviewLink() {
        return `/announcements/review/${this.announcementId}/podcast`;
    }

    get captions() {
        if (!this.podcast?.podcast_captions) {
            const caption = {
                podcast_id: this.podcast?.id || 0,
                content: '',
                video_voice_id: 74
            };
            this.podcast.podcast_captions = [caption];
        }
        return this.podcast?.podcast_captions;
    }

    get content() {
        return this.captions[0]?.content || '';
    }

    set content(value) {
        this.captions[0].content = value;
    }

    get videoVoiceId() {
        return this.captions[0]?.video_voice_id || 75;
    }

    set videoVoiceId(value) {
        this.captions[0].video_voice_id = value;
    }

    get hasTranscript() {
        return (
            this.podcast.podcast_captions &&
            this.podcast.podcast_captions.length > 1
        );
    }

    get canSeeTopics() {
        return (
            this.showTopics &&
            this.podcast.style_type === PodcastStyleType.INTERVIEW
        );
    }

    get canSeeTranscript() {
        return (
            this.showTranscript &&
            this.hasTranscript &&
            this.podcast.style_type === PodcastStyleType.INTERVIEW
        );
    }

    get canChangePodcastStyle() {
        return (
            this.isEditor &&
            this.podcast.user?.has_ai_features &&
            !!this.podcast.announcement?.author_request_assignment
        );
    }

    get loading() {
        return this.isLoading || this.generating;
    }

    get hasGeneratingStatus() {
        return this.podcast.status === PodcastStatus.GeneratingTranscript;
    }

    get canSelectInterviewStyleOption() {
        return (
            this.podcast.announcement?.supporting_urls.length &&
            wordCount(this.podcast.announcement.blog_post?.content || '') >= 300
        );
    }

    get podcastStyleItems() {
        return [
            {
                text: 'Standard',
                value: PodcastStyleType.STANDARD,
                disabled: false
            },
            {
                text: 'Interview',
                value: PodcastStyleType.INTERVIEW,
                disabled: !this.canSelectInterviewStyleOption
            }
        ];
    }

    get hasAllTopics() {
        const { topic_1, topic_2 } = this.podcast;
        return [topic_1, topic_2].includes('') === false;
    }

    get hasCompleteTranscript() {
        const emptyCaptions = this.podcast.podcast_captions?.filter(
            caption => caption.content === ''
        );

        return emptyCaptions?.length === 0;
    }

    get canGenerateTranscript() {
        return this.hasAllTopics && this.aboutCompany !== '';
    }

    get isStandardPodcast() {
        return this.podcast.style_type === PodcastStyleType.STANDARD;
    }

    get isInterviewStylePodcast() {
        return this.podcast.style_type === PodcastStyleType.INTERVIEW;
    }

    get activeAudioClipSrc() {
        if (this.podcast?.audio_clip_url) {
            return this.podcast?.audio_clip_url;
        }
        return '';
    }

    get audioClipRules() {
        return this.isInterviewStylePodcast ? 'required' : '';
    }

    get isInitialized() {
        return Boolean(this.podcast.id);
    }

    onMounted() {
        if (this.moduleId) {
            this.setPrePublishHook();

            this.load();
        } else {
            this.setAutoCheckStatusInterval();
        }
    }

    setPrePublishHook(isSet = true) {
        this.$emit('pre-publish', isSet ? this.prePublish.bind(this) : null);
    }

    askToCreate() {
        if (this.modules && !this.moduleId) {
            this.$emit('create', this.endpoint);
        }
    }

    onData(data: {
        podcast: TPodcast;
        video_voices: VideoVoice[];
        audio_clips: PodcastAudioClip[];
        hostVoiceIds: Array<number>;
    }) {
        if (data.podcast) {
            return this.commit(data);
        } else {
            this.review();
        }
    }

    async commit(data: {
        podcast: TPodcast;
        video_voices: VideoVoice[];
        audio_clips: PodcastAudioClip[];
        hostVoiceIds: Array<number>;
        errors?: string[];
    }) {
        this.softCommit(data.podcast);

        this.removeVideoPrLink();

        if (data.audio_clips) {
            this.audioClips = data.audio_clips;
        }

        if (data.video_voices) {
            this.video_voices = data.video_voices;
        }

        this.hostVoiceIds = data.hostVoiceIds;
        this.aboutCompany = this.podcast.announcement?.company.about ?? '';

        if (this.hasGeneratingStatus) {
            this.setPodcastGeneration();
        }

        if (data.podcast.style_type === PodcastStyleType.INTERVIEW) {
            this.initInterviewStylePodcast();
        }

        this.protectRoute();

        this.emitLinks();

        await this.setSaved();

        this.isReadyForChanges = true;

        return data;
    }

    softCommit(data: TPodcast) {
        if (this.isInitialized) {
            const softProperties: Array<keyof TPodcast> = [
                'is_editable',
                'is_awaiting_audio_preview',
                'is_live',
                'is_publishable',
                'status',
                'status_string'
            ];

            (Object.keys(data) as Array<keyof TPodcast>)
                .filter(key => softProperties.includes(key))
                .forEach(key => {
                    this.$set(this.podcast, key, data[key]);
                });
        } else {
            this.podcast = data;
        }
    }

    async save(foreground = true) {
        this.setSaving();

        this.generatePreview = await this.revalidate();

        return this.setData()
            .then(() => {
                if (foreground) {
                    this.onSave();
                }
            })
            .catch(() => {
                if (foreground) {
                    this.notifyError();
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    async autoSave() {
        return this.setData().catch(error => {
            if (!error.isIntercepted) {
                this.$store.dispatch('notification/error', error);
            }
        });
    }

    async setData() {
        return this.$http
            .post(this.sourceUrl, this.getDataToSave())
            .then(({ data }) => data)
            .then(({ data }) => {
                if (data?.errors) {
                    throw new Error(
                        'Unable to save Podcast. Please check the form for errors.'
                    );
                }

                return data;
            })
            .then(data => this.commit(data));
    }

    getDataToSave() {
        return {
            id: this.podcast.id,
            headline: this.podcast.headline,
            summary: this.podcast.summary,
            media_resources: this.podcast.media_resources,
            preview: this.generatePreview,
            podcast_captions: this.podcast?.podcast_captions,
            topic_1: this.podcast.topic_1,
            topic_2: this.podcast.topic_2,
            style_type: this.podcast.style_type,
            audio_clip_url: this.podcast.audio_clip_url
        };
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    async setSaved() {
        return this.$refs.form.reset();
    }

    async onSave() {
        const isValid = await this.revalidate();

        if (isValid) {
            this.review();
        }
    }

    async revalidate() {
        // reset existing errors
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-publish-podcast`
        );

        const isValid = await this.$refs.form.validate();

        if (!isValid) {
            this.notifyInvalid();
        }

        if (this.hasGeneratingStatus) {
            return false;
        }

        return isValid;
    }

    review() {
        this.$router.push(
            `/announcements/review/${this.announcementId}/podcast`
        );
    }

    protectRoute() {
        if (!this.podcast.is_editable) {
            this.review();
        }
    }

    emitLinks() {
        this.$emit('links', this.link);
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.setPrePublishHook(false);

        if (this.$refs.form.isDirty) {
            return this.save(false).then(() => next());
        } else {
            return next();
        }
    }

    prePublish() {
        if (!this.$refs.form?.isDirty) {
            return this.revalidate();
        }

        this.setSaving();

        return this.setData()
            .then(() => this.revalidate())
            .catch(() => {
                this.notifyError();

                return false;
            })
            .finally(this.setSaving.bind(this, false));
    }

    notifyInvalid() {
        this.$store.dispatch(
            'notification/info',
            'Podcast saved successfully. Please check the form for errors.'
        );
    }

    notifyError() {
        this.$store.dispatch(
            'notification/error',
            'Unable to save Podcast. Please check the form for errors.'
        );
    }

    onMediaFilesUpdate(resources: FormMediaResource[]) {
        this.podcast.media_resources = [...resources];

        this.$refs.media_resources.setFlags({
            dirty: true,
            changed: true,
            touched: true
        });
    }

    openGenerateTranscriptModal() {
        if (this.hasTranscript) {
            this.$store.dispatch('modal/open', 'GenerateTranscriptModal');
        } else {
            this.generateTranscript();
        }
    }

    generateTranscript() {
        this.saveAboutCompany();
        this._generateTranscript();
    }

    _generateTranscript() {
        const url = `/podcasts/transcript/${this.moduleId}`;

        this.generating = true;

        this.$http
            .post(url, this.getDataToSave())
            .then(() => {
                this.$store.dispatch('modal/close', 'GenerateTranscriptModal');

                if (!this.showTranscript) {
                    this.displayTranscript();
                }

                this.reload();

                this.setAutoCheckStatusInterval();
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            })
            .finally(() => {
                this.generating = false;
            });
    }

    displayTopics() {
        this.showTopics = true;
        this.showTranscript = false;
        this.$nextTick(() => {
            scrollTop();
        });
    }

    displayTranscript() {
        this.showTranscript = true;
        this.showTopics = false;
        this.$nextTick(() => {
            scrollTop();
        });
    }

    openGenerateStandardPodcastModal() {
        this.$store.dispatch('modal/open', 'GenerateStandardPodcastModal');
    }

    generateStandardPodcast() {
        const url = `/podcasts/reset/${this.moduleId}`;

        this.generating = true;

        this.$http
            .get(url)
            .then(() => {
                this.$store.dispatch(
                    'modal/close',
                    'GenerateStandardPodcastModal'
                );

                this.showTopics = false;
                this.showTranscript = false;

                this.load();
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            })
            .finally(() => {
                this.generating = false;
                this.isLoading = false;
            });
    }

    onCancelStandardPodcastGeneration() {
        this.podcast.style_type = PodcastStyleType.INTERVIEW;
    }

    onCancelGeneratePodcastModal() {
        this.podcast.style_type = PodcastStyleType.STANDARD;
    }

    setAutoCheckStatusInterval() {
        this.clearAutoCheckStatusInterval();

        this.autoCheckStatusInterval = setInterval(
            this.search.bind(this),
            3000
        );
    }

    clearAutoCheckStatusInterval() {
        if (this.autoCheckStatusInterval) {
            clearInterval(this.autoCheckStatusInterval);
        }
    }

    search() {
        this.setLoading();

        this.$http
            .get(`/podcasts/search?announcement_id=${this.announcementId}`)
            .then(({ data }) => {
                const { podcast }: { podcast: TPodcast } = data.data;

                if (!podcast) {
                    this.clearAutoCheckStatusInterval();
                    this.askToCreate();
                    return;
                }

                if (podcast.status !== PodcastStatus.GeneratingTranscript) {
                    this.generating = false;
                    this.clearAutoCheckStatusInterval();
                    this.reload();
                } else {
                    this.generating = true;
                    this.setGenerationMessage();
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    setGenerationMessage() {
        this.generatingMessage =
            'Generting transcript. Please wait or visit this page in some time.';
    }

    beforeDestroy() {
        this.clearAutoCheckStatusInterval();
    }

    setPodcastGeneration() {
        this.generating = true;
        this.setGenerationMessage();
        this.setAutoCheckStatusInterval();
    }

    removeVideoPrLink() {
        const captions = this.podcast.podcast_captions;

        if (captions === undefined || captions.length === 0) {
            return;
        }

        const hasVideoPlaceholder =
            captions[0].content.includes('{video_pr:link}');

        if (hasVideoPlaceholder) {
            captions[0].content = captions[0].content.replace(
                /{video_pr:link}\n\n/gi,
                ''
            );
        }

        this.podcast.podcast_captions = captions;
    }

    saveAboutCompany() {
        const companyId = this.podcast.announcement?.company_id;
        const contactName = this.podcast.announcement?.company.contact_name;

        if (companyId) {
            this.$http.post(
                `/companies/edit/${companyId}?user_id=${this.podcast.announcement?.user_id}`,
                {
                    about: this.aboutCompany,
                    contact_name: contactName // required to pass BE validation
                }
            );
        }
    }

    initInterviewStylePodcast() {
        if (this.hasGeneratingStatus) {
            this.setPodcastGeneration();
        }

        if (this.hasTranscript) {
            this.displayTranscript();
        } else {
            this.displayTopics();
        }
    }

    reload() {
        // resets isInitialized flag, so new data can be applied
        this.podcast.id = 0;

        this.$emit('reload');
    }
}
</script>

<style lang="scss" scoped>
.transcript-captions-wrapper::v-deep {
    display: flex;
    flex-direction: column;

    .v-input {
        width: 80%;
        margin-bottom: 1em;

        &:not(.host) {
            align-self: end;
            background-color: rgba($secondary-color, 0.1);
        }

        .v-text-field__slot {
            label {
                visibility: hidden;
            }

            textarea {
                margin-top: 0;
            }
        }
    }
}

.topic-input::v-deep {
    margin-bottom: 1em;

    .v-text-field__slot {
        label {
            visibility: hidden;
        }

        textarea {
            margin-top: 0;
        }
    }
}
</style>
